import config from './config'
import {postRequest, getRequest, putRequest, deleteRequest} from "../utils/http-request";
import MessageBox from '@/components/message-box'
import result from "element-ui/packages/result";
import {Message} from "element-ui";
import router from "../router";
import {upload} from "./service";
import axios from "axios";

//获取目录下所有文件列表
export function getFileList(dataForm, callback) {
	postRequest(config.url.file.fileList, dataForm,callback);
}

//通过条件查询文件列表
export function getFileFindList(dataForm, callback) {
	postRequest(config.url.file.findList, dataForm,callback);
}
//重命名
export function rename(dataForm, callback) {
	postRequest(config.url.file.rename, dataForm,callback);
}

//新建文件夹
export function createFolder(dataForm, callback) {
	postRequest(config.url.file.createFolder, dataForm,callback);
}

//判断文件是否已经存在
export function checkIsHave(dataForm, callback) {
	postRequest(config.url.file.checkIsHave, dataForm,callback);
}

//删除文件
export function deleteFile(dataForm, callback) {
	postRequest(config.url.file.deleteFile, dataForm,callback);
}

/**
 *    侦查附件上传情况,这个方法大概0.05-0.1秒执行一次
 */
let lastTime = 0;//上一次计算时间
let lastSize = 0;//上一次计算的文件大小
let startTime; //开始时间

export function countingSpeed() {
    /*计算间隔*/
    let nowTime = new Date().getTime();
    let intervalTime = (nowTime - lastTime) / 1000;//时间单位为毫秒，需转化为秒
    let intervalSize = event.loaded - lastSize;

    /*重新赋值以便于下次计算*/
    lastTime = nowTime;
    lastSize = event.loaded;

    /*计算速度*/
    let speed = intervalSize / intervalTime;
    if (isFinite(speed) === false) {
        speed = intervalSize;
    }
    /*计算剩余时间*/
    let leftTime = 0;
    if (speed < 0) {
        speed = -speed;
    }
    leftTime = ((event.total - event.loaded) / speed);
    speed = (speed / 1024 / 1024).toFixed(2);
    if (leftTime > 0) {
        leftTime = leftTime.toFixed(1);
    } else {
        leftTime = -leftTime.toFixed(1);
    }
    let countingList = [];
    countingList.speed = speed + "m/s";
    countingList.leftTime = leftTime + "s";
    return countingList;
}

function formatSeconds(value) {
    var secondTime = parseInt(value)/1000; // 秒
    var minuteTime = 0; // 分
    var hourTime = 0; // 小时
    if (secondTime >= 60) {
        minuteTime = parseInt(secondTime / 60);
        secondTime = parseInt(secondTime % 60);
        if (minuteTime >= 60) {
            hourTime = parseInt(minuteTime / 60);
            minuteTime = parseInt(minuteTime % 60);
        }
    }
    var result ="" +(parseInt(secondTime) < 10? "0" + parseInt(secondTime): parseInt(secondTime));

    if (minuteTime > 0) {
    result ="" + (parseInt(minuteTime) < 10? "0" + parseInt(minuteTime) : parseInt(minuteTime)) + ":" + result;
    }
    if (hourTime > 0) {
    result ="" + (parseInt(hourTime) < 10 ? "0" + parseInt(hourTime): parseInt(hourTime)) +":" + result;
    }
    return result;
}

//获取离线下载文件列表
export function getOfflineFilesList(dataForm, callback) {
    postRequest(config.url.file.offlineFileList, dataForm,callback);
}