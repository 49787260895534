const host = 'https://moyi.chengxy.cn:9000';
const companyCode = 'moyi001';
const appId = 'wx0ccc2f7008d53286'; // dingnvpvjjg0qxwdjejj，wx0ccc2f7008d53286

// const host = 'http://192.168.3.109:9000';
// const host = 'https://interview.nxzjkj.cn:65534';
// const companyCode = 'nxyz001';
// const appId = null; 

const sso = `${host}/sso`;
const cloud = `${host}/cloud`;

const url = {
	// 用户认证相关接口
	auth: {
		login: `${sso}/auth/login`,
		logout: `${sso}/auth/logout`,
		getUserByToken: `${sso}/auth/getUserByToken/`,
		register: `${sso}/auth/register`,
		changePassWord: `${sso}/auth/changePassword`,
	},
	//用户相关接口
	sysUser:{
		updateUser: `${sso}/sysUser/updateUser`, //更新用户基础信息
	},

	// 文件管理相关接口
	file: {
		getMemory: `${cloud}/file/getUsedMemory`,
		getImgUrl: `${cloud}/file/showThumb/`,
		fileList: `${cloud}/file/list`,
		findList: `${cloud}/file/findList`,
		deleteFile: `${cloud}/file/delete`,
		downloadFile: `${cloud}/file/download/`,
		folderDownload: `${cloud}/file/folderDownload/`,
		downloadFileZIP: `${cloud}/file/exportFileList`,
		checkFolderIsHave: `${cloud}/file/checkFolderIsHave`,
		createFolder: `${cloud}/file/createFolder`,
		rename: `${cloud}/file/rename`,
		checkIsHave: `${cloud}/file/checkIsHave`,
		fileUpload: `${cloud}/file/upload`,
		singleUpload: `${cloud}/file/singleUpload`,
		filePreview: 'http://192.168.3.51:8503/file/filePreview/',
		preview: `${cloud}/file/preview/`,
		offlineFileDownload: `${cloud}/offlineFile/download`,
		offlineFileList: `${cloud}/offlineFile/findList`,
		fileSizeFromUrl: `${cloud}/offlineFile/fileSizeFromUrl`,
		moveFiles: `${cloud}/file/moveFiles`,
	},

	// 文件分享相关接口
	share: {
		shareFiles: `${cloud}/share/files`,
		getShareFiles: '/share/route/',
		getShareFileList: `${cloud}/share/list`,
	},

	// 文件共享相关接口
	shared: {
		sharedFile: `${cloud}/shared/folder/`,
		cancelShared: `${cloud}/shared/cancel/`,
		sharedList: `${cloud}/file/findList`,
	},

	// 回收站相关接口
	recycle: {
		getRecycleList: `${cloud}/recycle/findList`,
		cleanRecycles: `${cloud}/recycle/clean`,
	},

	// 文件收集相关接口
	collect: {
		collectFolderList: `${cloud}/collect/list`,
		collectFileList: `${cloud}/collect/fileList`,
		createCollect: `${cloud}/collect/createCollect`,
		deleteCollectFolder: `${cloud}/collect/deleteCollectFolder`,
		downloadCollectFolder: `${cloud}/collect/zipDownload/`,
		collectFiles: '/collect/collectFiles/',
	},
	
	//文档相关接口
	notes: {
		addCover: `${cloud}/notesCover/add`,
		updateCover: `${cloud}/notesCover/update`,
		deleteCover: `${cloud}/notesCover/delete`,
		coverList: `${cloud}/notesCover/findList`,
		
		add: `${cloud}/notes/add`,
		update: `${cloud}/notes/update`,
		delete: `${cloud}/notes/delete`,
		list: `${cloud}/notes/findList`,
		
		typeList: `${cloud}/notesType/findList`,
		
	},
	
};

module.exports = {
	host,
	url,
	companyCode,
	appId
};