<template>
	<div class="files-main-list">
		<div class="file-toolbar__wrapper">
			<div class="btn-group__wrapper" style="float: left">
				<!--        <div style="display: none">-->
				<!--          <el-upload action="" ref="uploader"></el-upload>-->
				<!--        </div>-->
				<!-- 顶部操作按钮 -->
				<el-button-group>
					<!-- 上传控件 -->
					<input id="upload-input" type="file" ref="uploadInput" name="file" style="display: none;"
						@change="uploadFile" multiple />

					<input id="upload-input2" type="file" ref="uploadInput" name="file" style="display: none;"
						@change="uploadFile" webkitdirectory directory multiple />

					<el-button v-if="selectStatus == 0 && pasteStatus == 0" size="small" icon="el-icon-upload2"
						@click="toUpload">上传文件
					</el-button>
					<el-button size="small" icon="el-icon-upload" v-if="selectStatus == 0 && pasteStatus == 0"
						@click="toUploadDirectory">
						上传文件夹</el-button>
					<el-button size="small" icon="el-icon-folder-add" v-if="selectStatus == 0 && pasteStatus == 0"
						@click="createFolder">
						新建文件夹
					</el-button>
					<el-button size="small" icon="el-icon-folder-add"
						v-if="selectStatus == 1 && previewStatus == 1 && pasteStatus == 0" @click="previewImg">预览
					</el-button>
					<!-- <el-button size="small" icon="el-icon-cencle" v-if="selectStatus == 1" @click="allSelection">
						全选
					</el-button> -->
					<!-- <el-button size="small" icon="el-icon-share" v-if="selectStatus == 1 && pasteStatus == 0"
						@click="centerDialogVisible = true">分享
					</el-button> -->
					<el-button size="small" icon="el-icon-download" v-if="selectStatus == 1 && pasteStatus == 0"
						@click="downloadFile">下载
					</el-button>

					<!--          <el-button size="small" icon="el-icon-s-check" v-if="selectStatus == 1">共享</el-button>-->
					<!--          <el-button size="small" icon="el-icon-s-custom" v-if="selectStatus == 1">取消共享</el-button>-->
					<el-button size="small" icon="el-icon-edit"
						v-if="selectStatus == 1 && renameStatus == 1 && pasteStatus == 0" @click="rename">
						重命名
					</el-button>

					<!-- <el-button size="small" icon="el-icon-delete" v-if="selectStatus == 1" @click="copyFiles">复制					</el-button> -->
					<!-- <el-button size="small" icon="el-icon-delete" v-if="selectStatus == 1 && pasteStatus == 0"
						@click="moveFiles">移动 </el-button> -->
					<!-- 	<el-button size="small" icon="el-icon-delete" v-if="pasteStatus == 1" @click="cencleMove">取消移动
					</el-button>
					<el-button size="small" icon="el-icon-delete" v-if="pasteStatus == 1" @click="pasteFiles">粘贴
					</el-button> -->

					<el-button size="small" icon="el-icon-delete" v-if="selectStatus == 1 && pasteStatus == 0"
						@click="deleteFiles">删除
					</el-button>
					<el-button size="small" icon="el-icon-cencle" v-if="selectStatus == 1 && pasteStatus == 0"
						@click="cancelSelection">
						取消选中
					</el-button>
				</el-button-group>
			</div>
			<!-- 搜索框 -->
			<div class="btn-search__wrapper" style="float:right">
				<el-input placeholder="搜索我的文件" v-model="input" class="search__wrapper">
					<el-button slot="append" icon="el-icon-search"></el-button>
				</el-input>
			</div>
		</div>


		<div class="files-list-toolbar__wrapper">
			<div class="files-list-left__wrapper">
				<div>
					<span class="tittle" v-if="currentBasePath != ''" @click="lastPage"
						style="cursor:pointer; color: #3a8ee6">返回上一级</span>

					<span class="tittle" v-if="currentPathName != ''"> ｜ </span>

					<span class="tittle" @click="firstPage" style="cursor:pointer; color: #3a8ee6">全部文件 </span>

					<span class="tittle" v-if="currentPathName != ''">当前文件夹：{{ modifiedPathName() }}</span>

				</div>
			</div>
			<div class="files-list-right__wrapper">
				<span class="files-right-count">已加载全部，共{{ fileDataList.length }}个</span>
				<i @click="changesIconStatus" class="el-icon-menu" style="height: 30px; width: 30px; font-size: 1rem"
					v-if="iconStatus == 0"></i>
				<i @click="changesIconStatus" class="el-icon-s-unfold"
					style="height: 30px; width: 30px; font-size: 1rem;" v-if="iconStatus == 1"></i>
				<span @click="changesIconStatus">切换视图</span>
			</div>
		</div>

		<!-- 文件列表 -->
		<div class="no-main-file-list nd-main-list__table">
			<div class="nd-table is-show-header">
				<template>
					<div class="box">
						<div class="wrap">
							<div class="imgContent" @click="showImgViewer"></div>
							<!-- 图片预览 -->
							<el-image-viewer v-if="imgViewerVisible" :on-close="closeImgViewer" :url-list="imgList" />

							<div class="videoContent" @click="playVideo()"></div>
							<!-- 视频预览 -->
							<el-dialog title :visible.sync="dialogPlay" width="70%" @close="closeDialog">
								<video :src="videoUrl" autoplay class="video" style="max-height: calc(100vh - 30vh)"
									width="100%" controls="controls"></video>
							</el-dialog>
						</div>


						<div id="drop-area" :class="dropActive ? 'drop-active' : ''">
							<!-- 列表布局 -->
							<div class="tableBox" v-if="iconStatus == 0" v-loading="uploadLoading"
								element-loading-text="正在加载本地文件中..." element-loading-spinner="el-icon-loading"
								element-loading-background="rgba(0, 0, 0, 0.8)">
								<div class="grid">
									<el-table :data="fileDataList" style="width: 100%" height="calc('100% - 48px')"
										@selection-change="handleSelectionChange">
										<el-table-column type="selection" width="50">
										</el-table-column>
										<el-table-column width="40">
											<template slot-scope="scope">
												<img style="height: 23px; width: 23px;position: absolute;top:50%;transform: translateY(-50%);"
													:src="getImgSrc(scope.row)" alt="这是图片"
													@dblclick="openDir(scope.row)">
											</template>
										</el-table-column>
										<el-table-column prop="fileName" label="文件名称" min-width="240"
											show-overflow-tooltip></el-table-column>
										<el-table-column prop="fileSize" label="大小" width="240">
											<template slot-scope="scope">
												{{ formatFileSize(scope.row.fileSize) }}
											</template>
										</el-table-column>
										<el-table-column prop="suffix" label="扩展名" width="240"></el-table-column>
										<el-table-column label="创建日期" width="240">
											<template slot-scope="scope">{{ scope.row.createTime}}</template>
										</el-table-column>
										<el-table-column label="修改日期" width="240">
											<template slot-scope="scope">{{ scope.row.lastModifyTime }}</template>
										</el-table-column>
									</el-table>
								</div>
							</div>

							<!-- 格子布局 -->
							<div class="tableBox" v-if="iconStatus == 1" v-loading="uploadLoading"
								element-loading-text="正在加载本地文件中..." element-loading-spinner="el-icon-loading"
								element-loading-background="rgba(0, 0, 0, 0.8)">
								<div class="grid">
									<div class="grid-item" :class="{checked: item.checked}"
										v-for="(item,index) in fileDataList"
										@click="handleSelection(item, index, $event)" @dblclick="openDir(item)">
										<img :src="getImgSrc(item)">
										<span :title="item.fileName">{{ item.fileName }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>

		<!-- 文件详情 -->
		<section class="nd-detail nd-main-list__detail">
			<div class="nd-detail__title">
				文件详情
			</div>
			<div v-if="this.fileDetail.length === 1">
				<!--选中一个文件的时候-->
				<div class="nd-detail__img bg">
					<img :src="getImgSrc(this.fileDetail[0])" class="category">
				</div>

				<div class="nd-detail__props">
					<div class="nd-detail__name">
						<span>{{ this.fileDetail[0].fileName }}</span>
					</div>
					<div class="prop_tittle">
						创建时间:
					</div>
					<div class="prop_text">
						{{ this.fileDetail[0].createTime }}
					</div>
					<div class="prop_tittle">
						文件格式:
					</div>
					<div class="prop_text">
						{{ this.fileDetail[0].suffix }}
					</div>
					<div class="prop_tittle">
						文件大小:
					</div>
					<div class="prop_text">
						{{ formatFileSize(this.fileDetail[0].fileSize) }}
					</div>
					<div class="prop_tittle">
						所在目录:
					</div>
					<div class="prop_text">
						{{ this.fileDetail[0].parentPath }}
					</div>
				</div>
			</div>

			<div v-if="this.fileDetail.length > 1">
				<div class="nd-detail__empty">
					<img src="/cloud/image/file_type/folder.png" class="img">
					<p>共选中{{ this.fileDetail.length }}个文件</p>
				</div>
			</div>
		</section>

		<!-- 分享选项弹框 -->
		<el-dialog title="分享" :visible.sync="centerDialogVisible" width="400px" center>
			<div class="wp-share-file">
				<span>有效期： </span>
				<el-select v-model="validity" placeholder="请选择">

					<el-option v-for="item in validityData" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="wp-share-file">
				<span>是否加密： </span>
				<el-switch v-model="isEncrypt">
				</el-switch>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="shareFiles">创建链接</el-button>
			</span>
		</el-dialog>
		<!-- 分享结果弹框 -->
		<el-dialog title="分享" :visible.sync="shareFilesDialogVisible" width="30%" center>
			<div class="wp-share-file">
				<span style="color: #3a8ee6"><i class="el-icon-circle-check"></i>成功创建分享链接 </span>
			</div>
			<div class="wp-share-file">
				<el-input placeholder="分享链接" v-model="shareLink" :disabled="true">
				</el-input>
			</div>
			<div class="wp-share-file" v-if="isEncrypt === true">
				<span>提取码： </span>
				<el-input v-model="verificationCode" :disabled="true">
				</el-input>
			</div>
			<div class="wp-share-file" v-if="isEncrypt != true && multipleSelection.length === 1">
				<span>shell下载命令： </span>
				<el-input v-model="verificationCode" :disabled="true">
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="copyShareLink" v-if="isEncrypt === true">复制链接及提取码</el-button>
				<el-button type="primary" @click="copyShareLink1" v-if="isEncrypt != true">复制链接</el-button>
				<!--无需提取码-->
			</span>
		</el-dialog>

		<!-- 离线下载弹框 -->
		<el-dialog title="离线下载" :visible.sync="offlineDownloadDialogVisible" width="30%" center>
			<div class="wp-share-file">
				<span style="color: #3a8ee6"><i class="el-icon-circle-check"></i>请输入下载地址 </span>
			</div>
			<div class="wp-share-file">
				<el-input placeholder="下载地址" v-model="offlineDownloadLink" @input="getOfflineDownloadName">
				</el-input>
			</div>
			<div class="wp-share-file" v-if="isEncrypt === true">
				<span>文件名： </span>
				<el-input placeholder="文件名" v-model="offlineDownloadName">
				</el-input>
			</div>
			<div class="wp-share-file" v-if="isEncrypt === true">
				<span>文件大小： </span>
				<span>{{offlineDownloadSize}}</span>
			</div>


			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="addOfflineDownload" v-if="isEncrypt === true">加入离线下载</el-button>
			</span>
		</el-dialog>


		<!-- 文件上传列表 -->
		<div class="upload-list-dialog" id="uploadListDialog">
			<div class="dialog-header">
				<span>上传列表</span>
				<i class="el-icon-arrow-down" id="hideButton" @click="hideButton" style="display: none"></i>
				<i class="el-icon-arrow-up" id="displayButton" @click="displayButton"></i>
			</div>
			<div class="dialog-body">
				<div class="item">
					<div class="file-info">
						<div class="img">
							<span style="visibility: hidden">icon</span>
						</div>
						<div class="file-name">
							<span>文件(夹)名</span>
						</div>
						<div class="size">
							<span>大小</span>
						</div>
						<div class="size">
							<span>速度</span>
						</div>
						<div class="size">
							<span>剩余时间</span>
						</div>
						<div class="operation">
							<span>操作</span>
						</div>
					</div>
					<div class="el-progress file-progress" style="float: left; visibility: hidden">
						<el-progress :percentage="50" :show-text="false"></el-progress>
					</div>
				</div>
				<!-- 上传进度列表 -->
				<div class="item">
					<div class="file-info" v-for="(item, index) in fileList">
						<div class="img">
							<img :src="getImgSrc(item)" width="20" height="20">
						</div>
						<div class="file-name">
							<span>{{ item.name }}</span>
						</div>
						<div class="size">
							<span>{{ item.size }}</span>
						</div>
						<div class="size">
							<span>{{ item.speed }}</span>
						</div>
						<div class="size">
							<span>{{ item.leftTime }}</span>
						</div>
						<div class="operation">
							<i class="el-icon-video-pause"></i>
							<i class="el-icon-close"></i>
						</div>
						<div class="el-progress file-progress" style="float: left; margin: 4px;">
							<el-progress :percentage="item.progress" :show-text="false" :stroke-width="4"></el-progress>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import MessageBox from '@/components/message-box'
	import {
		countingSpeed,
		deleteFile,
		getSharedList
	} from "../../../api/shared";
	import {
		getRequest,
		postRequest,
		getContentLong
	} from "../../../utils/http-request";
	import config from "../../../api/config";
	import axios from "axios";
	import {
		upload
	} from "../../../api/service";
	import {
		Message
	} from "element-ui";
	import router from "../../../router";
	import qs from 'qs';
	import Utils from "../../../assets/util";
	// import { forEach } from 'core-js/js/array';

	import {
		jsonp
	} from 'vue-jsonp';

	export default {
		name: "files",
		data() {
			return {
				//当前访问路径
				currentPathName: '', //当前节点
				currentBasePath: '',
				multipleSelection: [], //选中文件
				selectStatus: 0, //0为选择，1为选中文件	
				pasteStatus: 0, //0为默认，1为进入粘贴状态
				pasteFileIdList: [], //需要粘贴的文件id列表
				previewStatus: 0, // 是否显示预览
				renameStatus: 0, //是否显示重命名按钮
				iconStatus: 1, //0列表页，1为图标
				input: '',
				dataForm: {
					path: this.currentPathName,
					shared: true
				},
				fileDataList: [],
				dialogTableVisible: false,
				dialogFormVisible: false,
				form: {
					name: '',
					region: '',
					date1: '',
					date2: '',
					delivery: false,
					type: [],
					resource: '',
					desc: ''
				},
				formLabelWidth: '120px',
				fileList: [],
				uploadState: false,
				loading: false,
				complete: false,
				// isShift: false,  // 快捷键 shift 是否被按下
				isCtrl: false, // 快捷键 ctrl 是否被按下
				selectedState: [], // 上方div图切是否被多选中，如果多选了就把他的id放到这个列表里面，当然可以不用，直接在相应的列表对象加一个参数isSelected也行，具体看项目和个人编码习惯哈。
				// shiftNum: '', // shift 按下后选择的第一个div下标，因为shift的时候要点两个div都中间包裹的都要切换为被选中状态
				uploadLoading: false, //上传加载动画
				fileDetail: [],
				imgViewerVisible: false,
				imgList: [],
				dialogPlay: false,
				videoUrl: '', //预览视频地址
				dropActive: false, //拖拽上传
				centerDialogVisible: false, //分享弹窗
				validity: 1, //分享有效期
				validityData: [{ //分享有效期选项
					value: 1,
					label: '1天'
				}, {
					value: 7,
					label: '7天'
				}, {
					value: 30,
					label: '30天'
				}, {
					value: 365,
					label: '1年'
				}, {
					value: 365*100,
					label: '永久'
				}],
				isEncrypt: true, //分享是否加密
				shareFilesDialogVisible: false, //分享创建成功后弹窗
				offlineDownloadDialogVisible: false, //离线下载弹窗
				offlineDownloadLink: '', //离线下载链接
				offlineDownloadName: '', //离线下载文件名称
				offlineDownloadSize: '', //离线下载文件大小
				shareLink: '', //分享链接
				verificationCode: '', //提取码
				ignoreList: ['.DS_Store', '.svn', '.git'], //过滤文件名称列表
				sharedStatus: true
			}
		},
		components: {
			'el-image-viewer': () => import('../../../components/image/src/image-viewer')
		},
		mounted() {
			this.initGetFileList();

			// this.$refs.uploadInput.$children[0].$refs.input.webkitdirectory = true

			//拖拽上传
			let dropArea = document.getElementById('drop-area')
			//设置监听
			dropArea.addEventListener('drop', this.dropEvent, false)
			//拖动离开
			dropArea.addEventListener('dragleave', (e) => {
				e.stopPropagation()
				e.preventDefault()
				this.dropActive = false
			})
			//拖动进入
			dropArea.addEventListener('dragenter', (e) => {
				e.stopPropagation()
				e.preventDefault()
				this.dropActive = true
			})
			//拖动结束
			dropArea.addEventListener('dragover', (e) => {
				e.stopPropagation()
				e.preventDefault()
				this.dropActive = true
			})
		},
		computed: {
			selection() {
				return this.fileDataList.filter(item => item.checked)
			}

		},
		methods: {
			async initGetFileList() {
				let vm = this;
				await getSharedList({
					parentPath: this.currentPathName,
					shared: this.sharedStatus
				}, function(res) {
					if (res.code === 200 && res.success === true) {
						vm.fileDataList = res.data.content;
					} else {
						Message.error(res.message);
					}
				})
			},
			modifiedPathName() {
				var array = this.currentPathName.split("/");
				return array[array.length - 2];
			},
			//切换布局
			changesIconStatus() {
				this.iconStatus = this.iconStatus === 0 ? 1 : 0;
				//清空选中文件
				this.selectedState = [];
				this.multipleSelection = [];
				this.fileDetail = [];

				//修改预览按钮
				this.previewStatus = 0;
				//修改重名按钮
				this.renameStatus = 0;
			},
			//选中文件
			handleSelectionChange(val) {
				this.multipleSelection = val;
				if (val.length === 1) {
					switch (val[0].category) {
						case 1:
						case 3:
						case 4:
						case 6:
						case 8:
							this.previewStatus = 1; //显示预览按钮
							break;
					}

					this.renameStatus = 1;
				} else {
					this.previewStatus = 0;
					this.renameStatus = 0;
				}
				if (val.length > 0) {
					this.selectStatus = 1;
					this.fileDetail = this.multipleSelection;
					this.selectedState = [];
					for (let i = 0; i < val.length; i++) {
						let str = val[i].fileId
						this.selectedState.push(str)
					}
				} else {
					this.selectStatus = 0;
					this.selectedState = []
					this.fileDetail = []
				}
			},
			//全部选中
			allSelection() {
				this.selectStatus = 1;
				this.selectedState = []
				this.fileDataList.forEach((item) => {
					item.checked = true
				})
				this.multipleSelection = this.fileDataList
				this.fileDetail = this.multipleSelection

			},
			//取消选中
			cancelSelection() {
				this.selectStatus = 0;
				this.selectedState = []
				this.fileDetail = []
				this.multipleSelection = []
				this.fileDataList.forEach((item) => {
					item.checked = false
				})
			},
			//判断组合键多选
			handleSelection(item, index, e) {
				this.isCtrl = (e.metaKey || e.ctrlKey);
				// this.isShift = e.shiftKey;

				if (this.isCtrl) { // 如果按下的是ctrl
					let str = item.fileId // 这行代码没必要，但是案例是根据我项目改的，就懒得删了
					let i = this.selectedState.indexOf(str) // 判断选中列表中是否包含这个点击的div

					if (i < 0) {
						this.selectedState.push(str); // 如果不包含就加进去
						this.multipleSelection.push(item);
						this.$set(item, 'checked', !item.checked);
					} else {
						this.selectedState.splice(i, 1); // 如果包含就删，表示按下ctrl键点一下选中，在点一下取消选中
						this.multipleSelection.splice(i, 1);
						this.$set(item, 'checked', false);
					}
					// } else if (this.isShift) {   // 如果按下的是shift
					//   if (this.shiftNum === '') {  // 如果还没点下第一个div
					//     this.shiftNum = index  // 让第一个div的下标赋值给shiftNum
					//     let str = item.fileId
					//     let i = this.selectedState.indexOf(str)
					//     if (i < 0) {
					//       this.selectedState.push(str)
					//       this.$set(item, 'checked', !item.checked);
					//     } else {
					//       this.selectedState.splice(i, 1);
					//       this.$set(item, 'checked', false)
					//     }
					//   } else {  // 如果点击第一个了
					//     if (this.shiftNum > index) {  // 选中第一个的索引大于当前点击的索引
					//       for (let j = index; j <= this.shiftNum; j++) {  // 把中间的都选中
					//         let str = this.fileDataList[j].fileId
					//         let i = this.selectedState.indexOf(str)
					//         if (i < 0) {
					//           this.selectedState.push(str)
					//         }
					//         this.$set(item, 'checked', !item.checked);
					//       }
					//     } else {
					//       for (let j = this.shiftNum; j <= index; j++) {
					//         let str = this.fileDataList[j].fileId
					//         let i = this.selectedState.indexOf(str)
					//         if (i < 0) {
					//           this.selectedState.push(str)
					//         }
					//         this.$set(item, 'checked', !item.checked);
					//       }
					//     }
					//   }
				} else {
					let str = item.fileId
					let i = this.selectedState.indexOf(str) // 判断选中列表中是否包含这个点击的div
					if (i < 0) { //不包含的情况下
						let lastCheckData = this.fileDataList.filter(item => item.checked); //获取上次选中
						if (lastCheckData.length > 0) { //如果上次有选中，就删掉，如果上次没有选中就不做判断
							for (let j = 0; j < lastCheckData.length; j++) {
								this.selectedState.splice(0, 1); //删掉上次选中
								this.multipleSelection.splice(0, 1);
								this.$set(lastCheckData[j], 'checked', false);
							}
						}

						this.selectedState.push(str) // 如果不包含就加进去
						this.multipleSelection.push(item);
						this.$set(item, 'checked', !item.checked);
					} else {
						this.selectedState.splice(i, 1); // 如果包含就删，表示点一下选中，再点一下取消选中
						this.multipleSelection.splice(i, 1);
						this.$set(item, 'checked', false);
					}
				}
				//选中的时候切换按钮
				if (this.selectedState.length > 0) {
					this.selectStatus = 1;
					this.fileDetail = this.multipleSelection
				} else {
					this.selectStatus = 0;
					this.fileDetail = []
				}

				if (this.selectedState.length === 1) {
					switch (this.multipleSelection[0].category) {
						case 1:
						case 3:
						case 4:
						case 6:
						case 8:
							this.previewStatus = 1; //显示预览按钮
							break;
						default:
							this.previewStatus = 0; //默认隐藏预览按钮
							break;
					}


					this.renameStatus = 1;
				} else {
					this.previewStatus = 0;
					this.renameStatus = 0;
				}
			},
			// 文件大小转换
			formatFileSize(fileSize) {
				if (fileSize == 0) {
					return "";
				}
				if (fileSize < 1024) {
					return fileSize + 'B';
				} else if (fileSize < (1024 * 1024)) {
					var temp = fileSize / 1024;
					temp = temp.toFixed(2);
					return temp + 'KB';
				} else if (fileSize < (1024 * 1024 * 1024)) {
					var temp = fileSize / (1024 * 1024);
					temp = temp.toFixed(2);
					return temp + 'MB';
				} else {
					var temp = fileSize / (1024 * 1024 * 1024);
					temp = temp.toFixed(2);
					return temp + 'GB';
				}
			},
			// 文件图标获取
			getImgSrc(item) {
				let isDir = item.isDir,
					suffix = item.suffix,
					category = item.category,
					fileId = item.fileId;
				let icon = 'other';
				if (category === 3 || category === 1) {
					icon = config.url.getImgUrl + fileId;
					return icon;
				} else {
					if (isDir == 1) {
						icon = "folder";
						if (item.shared) {
							icon = "share_folder";
						}
					} else {
						switch (category) {
							// case 1:
							//   icon = "video";
							//   break;
							case 2:
								icon = "music";
								break;
							case 4: //文本
								icon = suffix;
								if (icon == "doc" || icon == "docx") {
									icon = "doc";
								} else if (icon == "xls" || icon == "xlsx" || icon == "csv") {
									icon = "xls";
								} else if (icon == "ppt" || icon == "pptx") {
									icon = "ppt";
								}
								break;
							case 5: //应用
							case 6: //压缩包
								icon = suffix;

								if (icon == "tar" || icon == "tgz" || icon == "gz" || icon == "rar" || icon == "zip") {
									icon = "zip";
								}
								break;
							case 7:
								icon = "torrent";
								break;
							case 8:
								icon = "code";
								break;
							case 9:
							default:
								icon = "other";
								break;
						}
					}
					return `/cloud/image/file_type/${icon}.png`;
				}

			},
			//获取文件目录结构树
			async getFileTree(item) {
				let that = this;
				var path = item.fullPath || "";
				let dir = new Array();
				if (item.isFile) {
					let resFile = await this.fileSync(item);
					resFile.path = path;
					return resFile;
					// item为文件夹时
				} else if (item.isDirectory) {
					var dirReader = item.createReader();
					let entries = await that.readEntriesSync(dirReader);
					for (let i = 0; i < entries.length; i++) {
						let proItem = await that.getFileTree(entries[i]);
						dir.push(proItem);
					}
					return dir;
				}
			},
			/**
			 * 展平数组
			 * @param {Array} 需要展平的数组
			 * @param {Array} 展平后的数组
			 *
			 */
			flattenArray(array, result) {
				// console.log(array, flatArray);
				for (let i = 0; i < array.length; i++) {
					if (Array.isArray(array[i])) {
						this.flattenArray(array[i], result);
					} else {
						result.push(array[i]);
					}
				}
			},
			/**
			 * 获取文件
			 */
			fileSync(item) {
				return new Promise((resolve, reject) => {
					item.file(res => {
						resolve(res);
					});
				});
			},
			//读取文件夹下的文件
			readEntriesSync(dirReader) {
				return new Promise((rel, rej) => {
					dirReader.readEntries(res => {
						rel(res);
					});
				});
			},
			//拖拽上传事件回调
			async dropEvent(e) {
				this.dropActive = false

				//阻止事件冒泡
				e.stopPropagation();
				//阻止事件的默认行为
				e.preventDefault();
				//储存获取到的文件列表
				let fileList = [];
				let DirectoryEntryList = [];

				if (e.dataTransfer.items) {
					// 拖拽对象列表转换成数组
					let items = new Array(...e.dataTransfer.items);
					// 获得DirectoryEntry对象列表
					for (let index = 0; index < items.length; index++) {
						let e = items[index];
						let item = null;
						//兼容不同内核的浏览器
						if (e.webkitGetAsEntry) {
							item = e.webkitGetAsEntry();
						} else if (e.getAsEntry) {
							item = e.getAsEntry();
						} else {
							this.$alert("浏览器不支持拖拽上传", "提示");
							return;
						}
						DirectoryEntryList.push(item);
					}
					if (DirectoryEntryList.length > 0) {
						for (let index = 0; index < DirectoryEntryList.length; index++) {
							let item = DirectoryEntryList[index];
							if (item) {
								//获取文件夹目录
								let FileTree = await this.getFileTree(item);
								// 拿到目录下的所有文件
								if (Array.isArray(FileTree)) {
									//展平文件夹
									this.flattenArray(FileTree, fileList);
								} else {
									//方便后续处理，单文件时也包装成数组
									fileList.push(FileTree);
								}
							}
						}
					}
				}

				let files = [];
				//修改文件名为路径
				fileList.forEach((fileItem) => {
					//过滤文件
					if (this.ignoreList.indexOf(fileItem.name) < 0) {
						//添加文件
						files.push(new File([fileItem], fileItem.path));
					}
				})
				//console.log(files);
				this.dropUploadFile(files)
			},

			dropUploadFile(fileList) {
				this.fileListUpload(fileList, this.currentPathName, function(result) {
					if (result.code === '200') {
						let vm = this;
						getSharedList(vm.dataForm, function(res) {
							if (res) {
								vm.fileDataList = res.data;
								this.$message.success(result.msg);
							}
						})
					} else {
						this.$message.info(result.msg);
					}
				}, 0);
			},

			//点击按钮 上传文件列表
			uploadFile(event) {
				let fileList = event.target.files;

				this.fileListUpload(fileList, this.currentPathName, function(result) {
					if (result.code === '200') {
						let vm = this;
						getSharedList(vm.dataForm, function(res) {
							if (res) {
								vm.fileDataList = res.data;
								this.$message.success(result.msg);
							}
						})
					} else {
						this.$message.info(result.msg);
					}
				}, 0);
			},

			//点击上传文件
			toUpload() {
				document.getElementById('upload-input').click()
			},
			//点击上传文件夹
			toUploadDirectory() {
				document.getElementById('upload-input2').click()
			},
			//离线下载文件
			offlineDownload() {
				this.offlineDownloadName = '';
				this.offlineDownloadLink = ''
				//显示弹框
				this.offlineDownloadDialogVisible = true;
			},
			//获取路径中的文件名
			getOfflineDownloadName() {
				this.offlineDownloadName = decodeURI(this.offlineDownloadLink.substring(this.offlineDownloadLink
					.lastIndexOf("/") + 1));
				this.getOfflineFileSize(this.offlineDownloadLink);
			},
			//获取离线文件大小
			getOfflineFileSize(url) {
				getRequest(config.url.fileSizeFromUrl + '?url=' + url).then(res => {
					if (res.code === 200 && res.success === true) {
						this.offlineDownloadSize = this.formatFileSize(res.data);
					} else {
						Message.error(res);
					}
				})
			},
			//加入离线下载
			addOfflineDownload() {
				if (this.offlineDownloadLink === '') {
					this.$message.error('填写下载地址！');
				} else if (this.offlineDownloadName === '') {
					this.$message.error('填写文件名！');
				} else {
					let dataForm = {
						"url": this.offlineDownloadLink,
						"name": this.offlineDownloadName,
						"path": this.currentPathName,
					};
					this.$confirm('此操作将文件下载到当前目录, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {

						postRequest(config.url.offlineFileDownload, dataForm).then(res => {
							if (res.code === 200 && res.success === true) {
								Message.success(res.message);
							} else {
								Message.error(res.message);
							}
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消下载'
						});
					});
				}

			},
			//文件上传列表伸缩
			hideButton() {
				document.getElementById("uploadListDialog").style.bottom = "-255px";
				document.getElementById("hideButton").style.display = "none";
				document.getElementById("displayButton").style.removeProperty("display");
				document.getElementById("uploadListDialog").style.removeProperty("width");
				document.getElementById("uploadListDialog").style.removeProperty("min-width");
			},
			displayButton() {
				document.getElementById("uploadListDialog").style.bottom = "0px";
				document.getElementById("uploadListDialog").style.width = "25%";
				document.getElementById("uploadListDialog").style.minWidth = "600px";
				document.getElementById("displayButton").style.display = "none";
				document.getElementById("hideButton").style.removeProperty("display");
			},

			//文件上传
			fileListUpload(fileList, path, callback, imageIndex) {
				this.uploadLoading = true;
				//先判断是否有重名
				let param = new FormData()
				param.append('path', path);
				// param.append('isDir', 0); //非文件夹上传

				for (let i = 0; i < fileList.length; i++) {
					param.append('filesName', fileList[i].name);
				}

				//选完文件 创建对应数量的对象放进去
				this.fileList = Object.keys(fileList).map((f) => {
					return {
						name: '',
						size: '',
						speed: '',
						leftTime: '',
						progress: 0
					}
				})

				postRequest(config.url.checkIsHave, param).then(result => {
					this.uploadLoading = false;
					if (result.code === 200 && parseInt(result.message) > 0) {
						MessageBox.confirm('目标包含' + result.message + ' 个同名文件', {
							btn: ['替换所有', '保留重复', '让我决定'],
							type: 'info'
						}).then(({
							type
						}) => {
							if (type === 0) { //替换所有
								for (let i = 0; i < fileList.length; i++) {
									fileList[i].uploadType = 1;
								}
								this.uploadFileAjax(fileList, path, callback, imageIndex);
							}
							if (type === 1) { //保留重复
								let fileNameList = result.data;
								for (let i = 0; i < fileList.length; i++) {
									let fileName = fileList[i].name;
									if (fileNameList.includes(fileName)) { //如果已有，不覆盖
										fileList[i].uploadType = -1;
									} else {
										fileList[i].uploadType = 0;
									}
								}
								this.uploadFileAjax(fileList, path, callback, imageIndex);
							}
							if (type === 2) { //让我决定
								let fileNameList = result.data;

								let getNextFile = function(currentIndex) {
									if (currentIndex >= fileList.length - 1) {
										fileList = $.map(fileList, function(file) {
											if (removeList.indexOf(file) == -1) {
												return file;
											}
										})
										this.uploadFileAjax(fileList, path, callback,
											imageIndex);
										return false;
									}
									return fileList[currentIndex + 1];
								}

								let doUpload = function(file, uploadType, callback) {
									//执行逻辑
									file.uploadType = uploadType;
									callback();
								}
								let removeList = []
								let checkFile = function(file) {
									if (!file) {
										return
									}
									currentIndex++;
									let fileName = file.name;
									if (fileNameList.includes(fileName)) {
										MessageBox.confirm('目标已包含一个名为“" ' + fileName +
											' "的文件', {
												btn: ['替换', '保留', '取消上传'], //按钮
												type: 'info'
											}).then(({
											type
										}) => {
											if (type === 0) { //替换
												doUpload(file, 1, function() {
													checkFile(getNextFile(
														currentIndex
													));
												})
											}
											if (type === 1) { //保留
												doUpload(file, -1, function() {
													checkFile(getNextFile(
														currentIndex
													))
												})
											}
											if (type === 2) { //取消上传
												removeList.push(file);
												checkFile(getNextFile(
													currentIndex))
											}
										})
									} else {
										doUpload(file, 0, function() {
											checkFile(getNextFile(currentIndex))
										})
									}
								};
								let currentIndex = -1;
								checkFile(fileList[0]);
							}
							// Message.success(`${type}`)
						});
					} else {
						for (let i = 0; i < fileList.length; i++) {
							fileList[i].uploadType = 0;
						}
						this.uploadFileAjax(fileList, path, callback, imageIndex);
					}
				})
			},

			async uploadFile2(url, formData) {
				try {
					const response = await axios.post(url, formData);
					console.log(response);
				} catch (error) {
					console.error(error);
				}
			},
			uploadFileAjax(fileList, path) {
				document.getElementById("uploadListDialog").style.bottom = "0px";
				document.getElementById("uploadListDialog").style.width = "25%";
				document.getElementById("uploadListDialog").style.minWidth = "600px";
				document.getElementById("displayButton").style.display = "none";
				document.getElementById("hideButton").style.removeProperty("display");


				for (let i = 0; i < fileList.length; i++) {
					//过滤文件
					if (fileList[i].name == "." || fileList[i].name == ".DS_Store") {
						continue;
					}

					this.fileList[i].name = fileList[i].name;
					this.fileList[i].size = this.formatFileSize(fileList[i].size);


					let formData = new FormData();

					formData.append("file", fileList[i]);
					formData.append("parentPath", path);
					formData.append("permission", "private");
					formData.append("lastModifyTime", fileList[i].lastModified); //文件属性
					formData.append("uploadType", fileList[i]
						.uploadType); //是否有重复，0正常没有重复的可以不管，1、有重复发，全部覆盖， -1、有重复发，全部不覆盖


					this.loading = true;
					this.complete = false;

					let data = {
						parentPath: path,
						permission: 'private',
						lastModifyTime: fileList[i].lastModified,
						uploadType: fileList[i].uploadType
					};

					// formData.append('fileInfoVO', JSON.stringify(data));
					// this.uploadFile2(config.url.singleUpload, formData);
					this.uploading = true

					// 发送文件到服务器，并读取进度
					axios.post(config.url.singleUpload, formData, {
						onUploadProgress: (progressEvent) => {
							let completeVal =
								(progressEvent.loaded / progressEvent.total) * 100 || 0;
							let countingList = countingSpeed();
							this.fileList[i].speed = countingList.speed;
							this.fileList[i].leftTime = countingList.leftTime;
							this.fileList[i].progress = completeVal;
							this.uploadState = false;
						}
					})

					// config.url.fileUpload,
					// upload.uploadFile(

					// 		"http://192.168.3.88:8503/file/singleUpload",
					// 		formData,
					// 		(progressEvent) => {
					// 			let completeVal =
					// 				(progressEvent.loaded / progressEvent.total) * 100 || 0;
					// 			let countingList = countingSpeed();
					// 			this.fileList[i].speed = countingList.speed;
					// 			this.fileList[i].leftTime = countingList.leftTime;
					// 			this.fileList[i].progress = completeVal;
					// 			this.uploadState = false;
					// 		}
					// 	).then((res) => {
					// 		if (res.status === 200) {
					// 			fileList[i].progress = 100;
					// 			this.uploadState = true;

					// 			this.loading = false;
					// 			this.complete = true;
					// 			this.initGetFileList();
					// 			Utils.$emit('getMemory');
					// 			Message.success({
					// 				message: '上传成功'
					// 			});
					// 		} else {
					// 			Message.error({
					// 				message: '上传失败'
					// 			});
					// 			this.loading = false;
					// 			this.complete = false;
					// 		}
					// 	}).catch((thrown) => {
					// 		if (thrown.status === 401) {
					// 			Message.error({
					// 				message: '登陆超时，请重新登陆！'
					// 			});
					// 			router.replace("/");
					// 			return;
					// 		} else {
					// 			Message.error({
					// 				message: JSON.stringify(thrown)
					// 			});
					// 		}
					// 	})
				}
			},

			//下载文件
			downloadFile() {
				if (this.multipleSelection.length === 0) {
					this.$message.error('请选择文件!');
				} else if (this.multipleSelection.length === 1 && this.multipleSelection[0].isDir === 0) {
					let file = this.multipleSelection[0];

					const link = document.createElement('a');
					link.href = `${config.url.downloadFile}${file.fileId}`;
					link.setAttribute('download', file.fileName);
					document.body.appendChild(link);
					link.click();

					//如果是单个文件
					/*axios({
						url: `${config.url.downloadFile}${file.fileId}`,
						method: 'GET',
						responseType: 'blob', // important
					}).then((response) => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', file.fileName);
						document.body.appendChild(link);
						link.click();
					});*/

				} else {
					//如果是文件夹 或者 多个文件，则启动压缩下载
					let fileIdList = [];
					for (let i = 0; i < this.multipleSelection.length; i++) {
						const file = this.multipleSelection[i];
						fileIdList.push(file.fileId);
					}

					let zipName = this.multipleSelection[0].fileName;
					if (fileIdList.length > 1) {
						zipName += "等多个文件";
					}


					axios({
						// url: `${config.url.folderDownload}${file.fileId}`,
						url: `${config.url.downloadFileZIP}?zipName=${zipName}&fileIdList=${fileIdList}`,
						method: 'GET',
						responseType: 'blob', // important
					}).then((response) => {
						let blob = new Blob([response.data], {
							type: 'application/zip'
						})
						let url = window.URL.createObjectURL(blob)
						const link = document.createElement('a')
						link.href = url
						link.download = zipName + '.zip';
						link.click()
						URL.revokeObjectURL(url)
					});
				}
			},

			//打包下载文件
			downloadFileZIP() {
				if (this.multipleSelection.length === 0) {
					this.$message.error('请选择文件!');
				} else {
					let fileIdList = [];
					for (let i = 0; i < this.multipleSelection.length; i++) {
						const file = this.multipleSelection[i];
						fileIdList.push(file.fileId);
					}

					axios({
						url: `${config.url.downloadFileZIP}?zipName=abc&fileIdList=${fileIdList}`,
						method: 'GET',
						responseType: 'blob', // important
					}).then((response) => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', file.fileName);
						document.body.appendChild(link);
						link.click();
					});
				}

			},

			//创建文件夹
			createFolder() {
				this.$prompt('请输入文件夹名称', '创建文件夹', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /\S/,
					inputErrorMessage: '文件名不能为空'
				}).then(({
					value
				}) => {
					let data = {
						"path": this.currentPathName,
						"folderName": value
					};
					//先判断文件夹名是否已经存在
					// postRequest(config.url.checkFolderIsHave, data).then(res => {
					//   if (res.code === 200 && res.data === 0) {
					//
					//   } else {
					//     Message.error(res.message);
					//   }
					// })
					postRequest(config.url.createFolder, data).then(result => {
						if (result.code === 200) {
							this.initGetFileList();
							Message.success('新建成功！');
						} else {
							Message.error('新建失败');
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消新建'
					});
				});
			},

			//重命名
			rename() {
				if (this.multipleSelection.length === 0) {
					this.$message.error('请选择文件！');
				} else if (this.multipleSelection.length > 1) {
					this.$message.error('请选择单个文件！');
				} else {
					this.$prompt('请输入新文件名', '重命名', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						inputPattern: /\S/,
						inputErrorMessage: '文件名不能为空',
						inputValue: this.multipleSelection[0].fileName
					}).then(({
						value
					}) => {
						if (value === this.multipleSelection[0].fileName) {
							Message.error('文件名未作修改，请重新输入！');
						} else {
							let dataFrom = {
								"fileName": value,
								"fileId": this.multipleSelection[0].fileId
							};
							postRequest(config.url.rename, dataFrom).then(res => {
								if (res.code === 200 && res.success === true) {
									Message.success("修改成功！");
									this.initGetFileList();
									//清空选中文件
									this.selectedState = [];
									this.multipleSelection = [];
									this.fileDetail = [];
								} else {
									Message.error(res.message);
								}
							})
						}
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '取消重命名'
						});
					});
				}
			},
			//删除选中文件
			deleteFiles() {
				if (this.selectedState.length === 0) {
					this.$message.error('请选择文件！');
				} else {
					let dataForm = {
						"fileIds": this.selectedState
					};
					this.$confirm('此操作将文件移动到回收站, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						postRequest(config.url.deleteFile, dataForm).then(res => {
							if (res.code === 200 && res.success === true) {
								Message.success(res.message);
								// location.reload();
								this.initGetFileList();
								Utils.$emit('getMemory');
								//清空选中文件
								this.selectedState = [];
								this.multipleSelection = [];
								this.fileDetail = [];
							} else {
								Message.error(res.message);
							}
						})
					}).catch(() => {

						this.$message({
							type: 'info',
							message: '已取消删除',
						});
					});
				}
			},
			//复制选中文件
			copyFiles() {
				if (this.selectedState.length === 0) {
					this.$message.error('请选择文件！');
				} else {
					let dataForm = {
						"fileIds": this.selectedState
					};
					this.$confirm('此操作将文件移动到回收站, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						postRequest(config.url.copyFile, dataForm).then(res => {
							if (res.code === 200 && res.success === true) {
								Message.success(res.message);
								// location.reload();
								this.initGetFileList();
								Utils.$emit('getMemory');
								//清空选中文件
								this.selectedState = [];
								this.multipleSelection = [];
								this.fileDetail = [];
							} else {
								Message.error(res.message);
							}
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						});
					});
				}
			},
			//移动选中文件
			moveFiles() {
				if (this.selectedState.length === 0) {
					this.$message.error('请选择文件！');
				} else {
					this.pasteStatus = 1;
					//保存选中的文件
					this.selectedState.forEach((item) => {
						this.pasteFileIdList.push(item);
					})
				}
			},
			//取消移动
			cencleMove() {
				this.pasteStatus = 0;
				this.pasteFileIdList = [];
			},
			//粘贴文件
			pasteFiles() {
				if (this.pasteFileIdList.length === 0) {
					this.$message.error('请选择文件！');
				} else {
					let dataForm = {
						"fileIds": this.pasteFileIdList,
						"path": this.currentPathName,
					};
					this.$confirm('此操作将文件移动当前目录, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {

						postRequest(config.url.moveFiles, dataForm).then(res => {
							if (res.code === 200 && res.success === true) {
								Message.success(res.message);
								// location.reload();
								this.initGetFileList();
								Utils.$emit('getMemory');
								//清空选中文件
								this.selectedState = [];
								this.multipleSelection = [];
								this.fileDetail = [];
							} else {
								Message.error(res.message);
							}
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						});
					});
				}
			},

			//分享
			shareFiles() {
				if (this.selectedState.length === 0) {
					this.$message.error('请选择文件！');
				} else {
					let isDirCount = 0;
					for (let i = 0; i < this.multipleSelection.length; i++) {
						if (this.multipleSelection[i].isDir === 1) {
							isDirCount++;
						}
					}
					if (isDirCount > 0) {
						Message.error("暂不支持文件夹分享，请重新选择！")
					} else {
						let dataForm = {
							"fileIds": this.selectedState,
							"isEncrypt": this.isEncrypt,
							"validity": this.validity
						};
						postRequest(config.url.shareFiles, dataForm).then(res => {
							if (res.code === 200 && res.success === true && this.isEncrypt === true) {
								Message.success("创建成功！");
								this.centerDialogVisible = false; //关闭窗口
								this.shareLink = window.location.origin + '/cloud' + config.url
									.share.config.url
									.share.getShareFiles + res
									.data.shareId;
								this.verificationCode = res.data.code
								this.shareFilesDialogVisible = true;
							} else if (res.code === 200 && res.success === true && this.isEncrypt !=
								true && this
								.multipleSelection.length === 1) {
								Message.success("创建成功！");
								this.centerDialogVisible = false; //关闭窗口
								this.shareLink = window.location.origin + '/cloud' + config.url
									.share.getShareFiles + res
									.data.shareId;
								this.verificationCode = 'wget -O ' + this.multipleSelection[0]
									.fileName + ' ' +
									this.shareLink;
								this.shareFilesDialogVisible = true;
							} else if (res.code === 200 && res.success === true && this.isEncrypt !=
								true && this
								.multipleSelection.length > 1) {
								Message.success("创建成功！");
								this.centerDialogVisible = false; //关闭窗口
								this.shareLink = window.location.origin + '/cloud' + config.url
									.share.config.url
									.share.getShareFiles + res
									.data.shareId;
								this.verificationCode = '';
								this.shareFilesDialogVisible = true;
							} else {
								Message.error(res.message);
							}
						})
					}
				}
			},

			//复制验证码及其提取码
			copyShareLink() {
				var copyTest = '链接：' + this.shareLink + ' 提取码：' + this.verificationCode;
				var inputTest = document.createElement('input');
				inputTest.value = copyTest;
				document.body.appendChild(inputTest);
				inputTest.select();
				document.execCommand("Copy");
				inputTest.className = 'oInput';
				inputTest.style.display = 'none';
				Message.success('复制成功！');
			},

			copyShareLink1() {
				let copyTest = '';
				if (this.multipleSelection.length === 1) {
					copyTest = '链接：' + this.shareLink + ' shell下载命令：' + this.verificationCode;
				} else {
					copyTest = this.shareLink;
				}
				let inputTest = document.createElement('input');
				inputTest.value = copyTest;
				document.body.appendChild(inputTest);
				inputTest.select();
				document.execCommand("Copy");
				inputTest.className = 'oInput';
				inputTest.style.display = 'none';
				Message.success('复制成功！');
			},

			//双击打开文件夹
			openDir(item) {
				if (item.isDir === 1) {
					//清空选中文件
					this.selectedState = [];
					this.multipleSelection = [];
					this.fileDetail = [];
					//修改预览按钮
					this.previewStatus = 0;
					//修改重名按钮
					this.renameStatus = 0;
					this.currentPathName = item.parentPath + item.fileName + "/";
					//如果第一次进入文件夹，记录基础路径
					if (this.currentBasePath == '') {
						this.currentBasePath = this.currentPathName;
						this.sharedStatus = null;
					}

					this.initGetFileList();
				} else if (item.category === 3) { //双击预览图片
					let imgUrl = config.url.downloadFile + item.fileId;
					this.imgList.push(imgUrl);
					this.showImgViewer();
				} else if (item.category === 1) { //双击预览视频
					this.videoUrl = config.url.preview + item.fileId;
					this.playVideo();
				} else if (item.category === 4 || item.category === 6 || item.category ===
					8) { //双击预览 office，压缩包，代码文件
					if (item.suffix == 'other') {
						return;
					}
					var officeUrl = config.url.file.filePreview + item.fileId + "?fullfilename=" + item.fileName;
					window.open('http://moyi.chengxy.cn:8012/onlinePreview?url=' + window.btoa(
						encodeURIComponent(
							officeUrl)), "_blank");
				}
			},

			//预览按钮
			previewImg() {
				this.openDir(this.multipleSelection[0]);
			},

			showImgViewer() { //预览图片
				this.imgViewerVisible = true;
				const m = (e) => {
					e.preventDefault()
				};
				document.body.style.overflow = 'hidden';
				document.addEventListener("touchmove", m, false); // 禁止页面滑动
			},
			closeImgViewer() { //关闭预览图片
				this.imgViewerVisible = false;
				const m = (e) => {
					e.preventDefault()
				};
				document.body.style.overflow = 'auto';
				document.removeEventListener("touchmove", m, true);
				this.imgList = [];
			},

			playVideo() { //播放视频
				this.dialogPlay = true;
			},

			closeDialog() {
				this.videoUrl = "" //清空数据 关闭视频播放
			},

			viewOffice() { //预览office文件
				this.dialogPlay = true;
			},

			closeViewOffic() {
				this.office = "" //清空数据 关闭预览office
			},

			//上一页
			lastPage() {
				//清空选中文件
				this.selectedState = [];
				this.multipleSelection = [];
				this.fileDetail = [];
				//修改预览按钮
				this.previewStatus = 0;
				//修改重名按钮
				this.renameStatus = 0;
				if (this.currentPathName.endsWith("/")) {
					this.currentPathName = this.currentPathName.substring(0, this.currentPathName.lastIndexOf(
						'/'));

				}
				this.currentPathName = this.currentPathName.substring(0, this.currentPathName.lastIndexOf(
					'/'));
				this.currentPathName = this.currentPathName + "/";


				//如果返回到最后一级，则清空基础路径
				if (!this.currentPathName.startsWith(this.currentBasePath)) {
					this.currentBasePath = '';
					this.currentPathName = '';
					this.sharedStatus = true;
				}

				this.initGetFileList();
			},
			//全部文件
			firstPage() {
				//清空选中文件
				this.selectedState = [];
				this.multipleSelection = [];
				this.fileDetail = [];
				//修改预览按钮
				this.previewStatus = 0;
				//修改重名按钮
				this.renameStatus = 0;

				this.currentBasePath = '';
				this.currentPathName = '';
				this.sharedStatus = true;
				this.initGetFileList();
			}
		}
	}
</script>

<style>
	.files-right-count {
		font-size: 1rem;
	}

	.files-main-list {
		height: 100%;
		padding: 18px 24px 0 18px;
	}

	.btn-group__wrapper {
		display: inline-block;
		vertical-align: middle;
	}

	.search__wrapper {
		border: 1px solid #c5c5c5;
		border-radius: 20px;
		background: #f4f4f4;
	}

	.search__wrapper .el-input-group__append {
		border: none;
		background-color: transparent;
	}

	.search__wrapper .el-input__inner {
		height: 30px;
		line-height: 30px;
		border: none;
		background-color: transparent;
	}

	.search__wrapper .el-icon-search {
		font-size: 16px;
	}

	.search__wrapper:hover {
		border: 1px solid #D5E3E8;
		background: #fff;
	}

	.search__wrapper:hover .el-icon-search {
		color: #409eff;
		font-size: 16px;
	}

	.files-list-toolbar__wrapper {
		border-radius: 4px 4px 0 0;
		height: 40px;
		line-height: 40px;
		border-bottom-width: 0;
		overflow: hidden;
		padding: 6px 14px 0 12px;
		width: calc(100% - 20px);
	}

	.files-list-left__wrapper {
		float: left;
	}

	.files-list-left__wrapper .tittle {
		font-size: 1rem;
		font-size: 1rem;
		color: #03081a;
		font-weight: 700;
	}

	.files-list-right__wrapper {
		float: right;
		color: #afb3bf;
		font-size: 1rem;
	}

	.nd-main-list__table {
		margin-left: -18px;
	}

	.nd-main-list__grid,
	.nd-main-list__table {
		height: calc(100% - 80px);
		width: calc(100% - 272px);
		display: inline-block;
	}

	.nd-main-list__detail,
	.nd-main-list__grid,
	.nd-main-list__table {
		min-height: 356px;
	}

	.nd-table {
		height: 100%;
	}

	.nd-detail {
		padding: 16px;
		display: inline-block;
		background: #f5f6fa;
		border-radius: 8px;
		font-size: 1rem;
		position: relative;
		overflow: auto;
	}

	.nd-main-list__detail {
		height: calc(100% - 190px);
		width: 272px;
		margin-left: 16px;
	}

	.nd-main-list__detail,
	.nd-main-list__grid,
	.nd-main-list__table {
		min-height: 356px;
	}

	.nd-detail__title {
		color: #03081a;
		font-weight: 600;
		padding-bottom: 15px;
	}

	.nd-detail__empty {
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		left: 50%;
		position: absolute;
		text-align: center;
	}

	.nd-detail__empty img[data-v-0d6f9c00] {
		width: 60px;
		height: 60px;
	}

	.nd-detail__img {
		width: 240px;
		height: 240px;
		border-radius: 13px;
		position: relative;
	}

	.nd-detail__img.bg {
		background: #fff;
	}

	.nd-detail__img .category {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		max-height: 240px;
		max-width: 240px;
	}

	.nd-detail__props {
		color: #878c9c;
		padding: 10px;
	}

	.nd-detail__name {
		font-size: 14px;
		color: #454d5a;
		font-weight: 600;
		word-break: break-all;
		padding-top: 20px;
	}

	.nd-detail__props .prop_tittle {
		margin-top: 10px;
		font-size: 14px;
		color: #454d5a;
		font-weight: 600;
		word-break: break-all;
		text-align: left;
	}

	.nd-detail__props .prop_text {
		word-break: break-all;
		text-align: left;
	}

	.box {
		width: 100%;
		height: 100%;
	}

	.gap {
		width: 100%;
	}

	.tableBox {
		width: 100%;
		height: 100%;
		background: white;
	}

	.tableBox .grid {
		width: 100%;
		height: calc(100vh - 150px);
		overflow-y: scroll;
	}

	.el-table {
		height: 100%;
	}

	/* 格子布局样式 --- 开始*/
	.grid-item {
		float: left;
		width: 120px;
		height: 127px;
		margin: 4px 0 0 6px;
		border: 1px solid #fff;
		padding: 10px;
	}

	.grid-item.checked {
		border: 1px solid #90d8ff;
		border-radius: 5px;
		background-color: #f1f5fa;
	}

	/* 鼠标悬停*/
	.grid-item:hover {
		border: 1px solid #fff;
		border-radius: 5px;
		background-color: #f1f5fa;
	}

	/* 图片样式 */
	.grid-item>img {
		width: 64px;
		height: 64px;
		margin: 5px;
	}

	/* 文件名称 */
	.grid-item>span {
		font-size: 0.87rem;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		width: 100%;
		text-overflow: ellipsis;
		line-height: 1.2;
		white-space: normal;
		word-break: break-all;
	}

	/* 格子布局样式 --- 结束*/

	/* 上传列表 */
	.upload-list-dialog {
		position: fixed;
		right: 24px;
		bottom: -255px;
		width: 272px;
		min-height: 300px;

		border-top-left-radius: 7px;
		border-top-right-radius: 7px;
		border: 1px solid #e2e2e2;
		box-shadow: 0 0 10px #ccc;
	}

	.upload-list-dialog .dialog-header {
		padding-top: 15px;
		border-top-left-radius: 7px;
		border-top-right-radius: 7px;
		background-color: #fff;

		white-space: nowrap;
		text-overflow: ellipsis;
		color: #666;

		border-bottom: 1px solid #f7faff;
		text-indent: 1em;
		font-size: 14px;
		height: 44px;
	}

	.upload-list-dialog .item {
		margin: 0 15px;
	}

	.file-info {
		font-size: 14px;
	}

	.file-info>div {
		float: left;
	}

	.file-info .img {
		width: 5%;
		min-width: 25px;
	}

	.file-info .file-name {
		width: 35%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.file-info .size {
		width: 15%;
	}

	.file-info .operation {
		width: 15%;
	}

	.file-progress {
		width: 100%;
	}

	.dialog-body {
		font-family: "Microsoft YaHei", SimSun;
		background-color: white;
		font-size: 12px;
		color: #424e67;
		overflow: scroll;
		height: 255px;
	}

	.wp-share-file {
		margin-top: 8px;
	}

	.wp-share-file_dialog-footer {
		text-align: center;
		margin-top: 24px;
		font-weight: 700;
	}
</style>
